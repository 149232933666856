exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-icflix-js": () => import("./../../../src/pages/icflix.js" /* webpackChunkName: "component---src-pages-icflix-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-playground-js": () => import("./../../../src/pages/playground.js" /* webpackChunkName: "component---src-pages-playground-js" */),
  "component---src-pages-preferred-js": () => import("./../../../src/pages/preferred.js" /* webpackChunkName: "component---src-pages-preferred-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-swiffix-js": () => import("./../../../src/pages/swiffix.js" /* webpackChunkName: "component---src-pages-swiffix-js" */)
}

